<script setup lang="ts">
import PubSub from 'pubsub-js';
import { getService } from '@/common/services/ngBridge';
import i18n from '@/plugins/i18n';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { localAppConfigService } from '@/common/services/local-app-config';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { apiService as lpsSocketApiService } from '@/modules/lps-socket';
import TClock from '@/common/components/TClock.vue';
import InactivityBar from '@/common/components/InactivityBar.vue';
import TerminalInfo from '@/common/components/TerminalInfo.vue';
import { setIsModalShown, shouldOpenLobbyAfterIdleTime } from '@/modules/lobby/lobbyService';
import { useMarketingScreenSaverStore } from '@/modules/cms/marketing/marketingScreenSaverStore';
import { reloadIfAppOnHighMemoryUsage } from '@/modules/performance';
import TFooterActionsRight from './TFooterActionsRight.vue';
import TFooterActionsLeft from './TFooterActionsLeft.vue';

const configSettingsStore = useConfigSettingsStore();
const notificationsStore = useNotificationsStore();
const inactivityTime = configSettingsStore.data.terminalInactivityTime.value || 60000;
const { t } = i18n.global;

const onEndInactivityTimer = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $rootScope: any = getService('$rootScope');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loaderService: any = getService('loaderService');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const BetslipService: any = getService('BetslipService');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const switchView: any = getService('switchView');
  const { isScreenSaverEnabled, showScreenSaver } = useMarketingScreenSaverStore();

  // Close numpad
  $rootScope.$emit('closeNumPad:stop', '');
  // Stop loader
  loaderService.endLoader();
  // Emit end of session activity
  PubSub.publish('activity.sessionEnd');
  // Reset every game betslip
  BetslipService.resetBetslip();
  // Notify NAR if infoPoint mode is active
  if (localAppConfigService.data.infoPointActive) {
    lpsSocketApiService.dispatchInfoPointActivation();
  }
  // Go back to landing page
  switchView.goToLandingPage(true);

  // Scroll to top after inactivity timer ends
  const homeEl = document.getElementById('home-page');
  homeEl?.scrollTo({ behavior: 'smooth', top: 0 });

  if (shouldOpenLobbyAfterIdleTime()) {
    setIsModalShown(true);
  }

  if (isScreenSaverEnabled()) {
    showScreenSaver();
  }

  reloadIfAppOnHighMemoryUsage();

  notificationsStore.show({
    message: t('notifications_landing_page_inactivity'),
    type: TNotificationTypeEnum.info,
    delay: 3000,
  });
};
</script>

<template>
  <div class="t-footer-vue d-block sc-bg-500">
    <div class="float-left d-flex h-100 sc-px-3">
      <div
        class="sc-mr-3"
      >
        <InactivityBar
          name="terminal-inactivity"
          orientation="vertical"
          :on-end-inactivity-timer="onEndInactivityTimer"
          :transition-delay="5000"
          :time="inactivityTime"
        />
      </div>

      <div class="t-footer-toggle">
        <TerminalInfo class="sc-mr-3" />
      </div>

      <TFooterActionsLeft />
    </div>

    <div class="float-right d-flex h-100">
      <TFooterActionsRight />
      <TClock />
    </div>
  </div>
</template>

<style lang="scss">
@import 'app/styles/sass/z-index';
$footer-height: 64px;

.t-footer-vue {
    height: $footer-height;
    position: relative;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12), 0px -4px 16px rgba(0, 0, 0, 0.12);
    z-index: 1;
}

@media only screen and (max-width: 1024px) {
    .t-footer-toggle {
        display: none !important;
    }
}

.footer-button-label {
  display: none;
}

@media screen and (min-width: 1281px) {
  .footer-button-label {
    display: inline;
  }
}

@media screen and (max-width: 1280px) {
  .t-footer-button {
      width: 64px;
  }
}
</style>
