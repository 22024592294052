/**
  * @ngdoc service
  * @name 7Terminal.Core.services:switchView
  *
  * @description
  * It changes routes.
  *
  * @example
  * ```js
  * switchView.selectService({
  *   'LiveBetting',
  *   true
  * });
  * ```
* */

import { apiService as lpsSocketApiService } from '@/modules/lps-socket';
import { lobbyService } from '@/modules/lobby';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
/**
 * @ngInject
 */
function switchView(
  $q,
  $state,
  $rootScope,
  $filter,
  SevenGamesSvc,
  BootStrapData,
  loaderService
) {
  var current = '';

  /**
   * We have very complex routes (sometimes we need to hit server to know which route is next)
   * and it is hard to know which children route to hit. This service calls logic for each registered
   * route inside object and resolve its next route.
   *
   * Each route resolver must implement getRoute() method and return object with
   * two properties:
   *
   *  route: full name of next sate
   *  params: params for next state
   *
   * @type {{results: {getRoute: Function}}}
   */
  var RouteResolver = {
    results: {
      getRoute: function () {
        return {
          route: 'app.results.ticketcheck',
          params: {}
        };
      }
    }
  };

  function setRegularView(view, param) {
    var nextView = view.toLowerCase();

    if (current === nextView) {
      // stupid fix, refresh route - $route.load() not working :( ...
      $state.go('app');
    }
    param = param || {};

    // lets try to resolve route
    if (Object.prototype.hasOwnProperty.call(RouteResolver, nextView)) {
      return $q.when(RouteResolver[nextView].getRoute(current, nextView)).then(function (response) {
        current = response.route;

        return $state.go(response.route, angular.extend(param, response.params));
      });
    }
    current = nextView;

    return $state.go('app.' + nextView, param);
  }

  function getLandingPageDetails() {
    const cmsStore = useGravitySettingsStore();
    const landingPage = cmsStore.getModuleDataKeyValue('config', 'landingPage');
    const landingPageLocalConfig = localStorage.getItem('app.landingPage');

    if (!landingPageLocalConfig && landingPage) {
      return checkGameAvailability(landingPage) ? landingPage : 'home';
    } if (landingPageLocalConfig) {
      return checkGameAvailability(landingPageLocalConfig) ? landingPageLocalConfig : 'home';
    }
    return 'home';
  }

  function checkGameAvailability(gameId) {
    var gameAvailable = $filter('filter')(
      BootStrapData.clientAppModules,
      { id: gameId },
      true
    );

    return gameAvailable && gameAvailable.length;
  }

  return {
    /**
   * @ngdoc property
   * @name 7Terminal.Core.services:switchView#activeGame
   * @propertyOf 7Terminal.Core.services:switchView
   */
    activeGame: {},

    /**
   * @ngdoc property
   * @name 7Terminal.Core.services:switchView#activeBets
   * @propertyOf 7Terminal.Core.services:switchView
   */
    activeBets: {},

    /**
   * @ngdoc property
   * @name 7Terminal.Core.services:switchView#initialized
   * @propertyOf 7Terminal.Core.services:switchView
   */
    initialized: false,

    /**
   * @ngdoc property
   * @name 7Terminal.Core.services:switchView#landingPage
   * @propertyOf 7Terminal.Core.services:switchView
   */
    landingPage: null,

    /**
     *
     * @ngdoc method
     * @name 7Terminal.Core.services:switchView#getLandingPageDetails
     * @methodOf 7Terminal.Core.services:switchView
     *
     * @description
     * Get lending page details.
     *
     */
    getLandingPageDetails: getLandingPageDetails,

    /**
     *
     * @ngdoc method
     * @name 7Terminal.Core.services:switchView#setLandingPage
     * @methodOf 7Terminal.Core.services:switchView
     *
     * @description
     * Set lending page.
     *
     */
    setLandingPage: function () {
      if (this.initialized) {
        return;
      }

      const landingPage = this.getLandingPageDetails();
      const params = {};
      const serviceType = landingPage !== 'home';
      const redirectToLobby = lobbyService.shouldOpenLobbyOnTerminalLoad();
      if (serviceType) params.gameId = landingPage;
      if (redirectToLobby) params.lobby = true;

      this.selectService(landingPage, serviceType, params);
      this.landingPage = landingPage;
      this.initialized = true;
    },

    /**
     *
     * @ngdoc method
     * @name 77Terminal.Core.services:switchView#goToLandingPage
     * @methodOf 7Terminal.Core.services:switchView
     * @param {boolean} idleTimeFinished
     * @description
     * Go to app landing page (home or game depending on setting).
     *
     */
    goToLandingPage: function (idleTimeFinished = false) {
      if (idleTimeFinished) {
        const params = {};
        const serviceType = this.landingPage !== 'home';
        const redirectToLobby = lobbyService.shouldOpenLobbyAfterIdleTime();
        if (serviceType) params.gameId = this.landingPage;
        if (redirectToLobby) params.lobby = true;

        this.selectService(this.landingPage, serviceType, params);
        return;
      }

      if (this.landingPage === 'home') {
        this.selectService(this.landingPage, false, null);
        return;
      }

      // If there are no route specifics defined, keep standard select service logic
      if (!RouteResolver['games.' + this.landingPage.toLowerCase()]) {
        $q.when(this.selectService(this.landingPage, 'product', { gameId: this.landingPage })).then(function () {
          // In order to reinitialize controller and it's deps reload the landing page
          return $state.reload($state.current.name);
        });

        return;
      }

      // Manually set active items because selectService will do reset to app.games -.-
      // Set active game in client core
      this.activeGame = SevenGamesSvc.setActiveGame(this.landingPage);

      // Get betting service of active game
      this.activeBets = this.activeGame.getBettingSvc();

      setRegularView('games.' + this.landingPage, false, true);
    },

    /**
     *
     * @ngdoc method
     * @name 7Terminal.Core.services:switchView#resolvePreviousView
     * @methodOf 7Terminal.Core.services:switchView
     *
     * @description
     * Returns to previews view.
     *
     */
    resolvePreviousView: function () {
      const gameState = /games/g.test($rootScope.uiRouterpreviousState.name);
      const termsConditionsState = /app.termsandconditions/g.test($rootScope.uiRouterpreviousState.name);
      const instructionsState = /app.instructions/g.test($rootScope.uiRouterpreviousState.name);

      if (gameState) {
        this.selectService(
          SevenGamesSvc.getActiveGameId(),
          true,
          { gameId: SevenGamesSvc.getActiveGameId() || undefined }
        );
      } else if (!instructionsState && !termsConditionsState) {
        // Don't toggle between instructions and termsAndConditions, just return to main menu
        // Remove lobby query parameter when lobby was previously opened in order to fix unexpected lobby loading
        const previousRouteName = $rootScope.uiRouterpreviousState.name;
        const previousRouteParams = {
          ...$rootScope.uiRouterpreviousStateParams,
          lobby: undefined
        };
        $state.go(previousRouteName, previousRouteParams);
      } else {
        this.goToLandingPage();
      }
    },

    /**
     *
     * @ngdoc method
     * @name 7Terminal.Core.services:switchView#selectService
     * @methodOf 7Terminal.Core.services:switchView
     *
     * @description
     * Select service function for setting common service properties like active game and bets
     *
     * @param {String} serviceName - name of service
     * @param {String} serviceType - type of service - true if game, else false
     * @param {Object} param - some params we need for service (end,start time for prematch)
     * @returns {Promise} - Returns promise that will resolve when service is selected
     */
    selectService: function (serviceName, serviceType, param) {
      var view;
      var isIframe;

      if (serviceType) {
        // Start loader
        loaderService.showLoader();
        // don't remove this !! else we get error on game service click, because this.activeGame gets changed
        // before $state has been changed, therefore view stays from previous service but activeGame is new service
        if ($state.current.name !== 'home') {
          $state.go('app.games');
        }
        // Set active game in client core
        this.activeGame = SevenGamesSvc.setActiveGame(serviceName);
        // get betting service of active game
        this.activeBets = this.activeGame.getBettingSvc();
        // Sends active tab info to local admin
        lpsSocketApiService.dispatchTabChange({
          cpvUuid: this.activeGame.cpvUuid || this.activeGame.info.cpvUuid
        }).catch(angular.noop);
        // End loader
        loaderService.endLoader();
      } else {
        lpsSocketApiService.dispatchTabChange({ window_title: 'home' }).catch(angular.noop);
      }

      view = serviceType ? 'games.' + this.activeGame.id : serviceName;
      isIframe = serviceType && this.activeGame.info.local.frame;

      if (isIframe) {
        view = 'games';
      }

      return setRegularView(view, param);
    }
  };
}

export default switchView;
