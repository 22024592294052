import { logService } from '@/common/services/logger';
import { errorParser } from '@/common/services/error-parser';
import { pingNarToKeepAlive } from './apiService';

const LOG_PREFIX = '[narService]';

const startNarKeepAlive = () => {
  const appTitle = localStorage.getItem('window.title') || '';
  logService.info(`${LOG_PREFIX} Start NAR appAlive pinging`, {
    code: 'T_NAR_ALIVE_PING_START',
    appTitle,
  });
  setInterval(() => {
    pingNarToKeepAlive(appTitle)
      .then(() => {
        logService.debug(`${LOG_PREFIX} Received success message from http appAlive request to NAR`, {
          code: 'T_NAR_KEEP_ALIVE_SUCCESS',
        });
      })
      .catch((err) => {
        logService.error(`${LOG_PREFIX} Failed to send appAlive to NAR`, {
          error: err,
          ...errorParser.parseUpstream(err),
          code: 'T_NAR_KEEP_ALIVE_ERROR',
        });
      });
  }, 15000);
};

export default {
  startNarKeepAlive,
};
