import master from '@nsftx/seven-gravity-gateway/master';
import { has } from 'underscore';
import { logService } from '@/common/services/logger';
import HooksManager from '@/common/services/HooksManager';
import { scanEventPubSub, SCAN_EVENT_ID } from '@/modules/handheld-scan';
import { viewResolvedEventPubSub, VIEW_RESOLVED_EVENT_ID } from '@/common/events';
import { useWidgetsStore } from '@/common/stores/widgets';

/**
  * @ngdoc service
  * @name 7Terminal.Integrator.services:GravityGatewayService
  *
  * @description
  * Wrapper around gatway service. It is used to load and commnicate with iframe using gravity master.
  *
  * @example
  * ```js
  * GravityGatewayService.initSlave({
  *
  * });
  * ```
* */

/**
 * @ngInject
 */
function GravityGatewayService(
  $q,
  $rootScope,
  $injector,
  $document,
  $log,
  $timeout,
  switchView,
  errorParser
) {
  var Gateway = null;
  var registeredModules = {};
  const destroyedIframes = {};
  const LOG_PREFIX = '[7T.Integrator]';

  const createEvent = (slaveEvent) => {
    let finalEvent;
    const modifiedEvent = { ...slaveEvent };

    // gatways sends code under keyboardButton and type under event,
    // re-assign and delete non standrd property
    modifiedEvent.code = slaveEvent.keyboardButton;
    modifiedEvent.type = slaveEvent.event;
    modifiedEvent.cancelable = true;
    delete modifiedEvent.keyboardButton;
    delete modifiedEvent.event;

    switch (modifiedEvent.type) {
    case 'click':
      finalEvent = new MouseEvent('click', modifiedEvent);
      break;
    case 'touchstart':
    case 'touchend':
      finalEvent = new TouchEvent(modifiedEvent.type, {
        // first touchstart event is dispatched to body instead of document,
        // make the event bubble so it reaches listener in document
        bubbles: true,
        ...modifiedEvent
      });
      break;
    case 'keydown':
    case 'keypress':
    case 'keyup':
      finalEvent = new KeyboardEvent(modifiedEvent.type, modifiedEvent);
      break;
    default:
      finalEvent = new Event(modifiedEvent.type, modifiedEvent);
    }
    return finalEvent;
  };
  function handleRouteSwitch(slaveData) {
    const widgetStore = useWidgetsStore();
    const widget = widgetStore.getWidgetByFrameId(slaveData.slaveId);
    const widgetGameId = widget?.when.values[0];
    const gameId = widgetGameId || slaveData.slaveId;
    const availableViews = {
      BettingArea: {
        view: gameId,
        product: true,
        params: {
          gameId
        }
      },
      Results: {
        view: 'results.integration',
        params: {
          gameId
        }
      },
      Instructions: {
        view: 'instructions.integration',
        params: {
          gameId
        }
      },
      TicketCheck: {
        view: 'results'
      }
    };
    const viewName = slaveData.data.name[0];
    const viewDefinition = availableViews[viewName];

    if (!has(availableViews, viewName)) {
      $log.warn('[7Terminal.Integrator] Error chaging UI to unknown state', {
        product_id: slaveData.slaveId,
        code: 'T_UNKNOWN_UI_STATE'
      });
    } else {
      viewResolvedEventPubSub.publish(VIEW_RESOLVED_EVENT_ID, { viewName });
      switchView.selectService(viewDefinition.view, viewDefinition.product, viewDefinition.params);
    }
  }

  function handleValidatePayin(slaveData) {
    const ticketService = $injector.get('ticketService');
    const localTicket = ticketService.generateTicket(slaveData.data.ticket);
    const TicketValidationService = $injector.get('TicketValidationService');
    const productId = localTicket.getProductDisplayId();
    TicketValidationService.validatePayin({ localTicket, ticket: slaveData.data.ticket }, productId)
      .then((response) => {
        $log.info('[7Terminal.Tickets] Payin event resolved.', {
          code: 'T_INTEGRATOR_VALIDATE_PAYIN_SUCCESS',
          product_displayid: productId,
          request_id: localTicket.getRequestUuid()
        });
        slaveData.resolve(response);
      }).catch((error) => {
        $log.error(`[7Terminal.Tickets] validatePayin error detected. ${slaveData.action} event rejected.`, {
          data: slaveData.data,
          product_displayid: productId,
          request_id: localTicket.getRequestUuid(),
          ...errorParser.parseUpstream(error),
          code: 'T_INTEGRATOR_VALIDATE_PAYIN_FAILED'
        });
        slaveData.reject(error);
      });
  }

  function handleGatewaySubscription() {
    Gateway.subscribe('*', function (slaveData) {
      var service = null;
      var e = null;
      var data;

      switch (slaveData.action) {
      case 'Tickets.Pay':
      case 'Tickets:Pay': // this one should be deprecated
      case 'Ticket:Payin.Send': // this one should be deprecated
        $log.debug(`${LOG_PREFIX} Tickets.Pay event received`, {
          data: slaveData.data,
          slave_id: slaveData.slaveId,
          code: 'T_INTEGRATOR_PAYIN_EVENT_RECEIVED'
        });
        $injector.get('ticketService').payinTicket(slaveData.data)
          .then(({
            ticket, message, code, requestUuid, productDisplayId
          }) => {
            if (ticket && slaveData.resolve) {
              slaveData.resolve({ ticket });
              $log.info(`${LOG_PREFIX} Pay in resolved to slave`, {
                request_id: requestUuid,
                product_displayid: ticket.getProductDisplayId(),
                code: 'T_INTEGRATOR_PAYIN_SUCCESS'
              });
            } else if (slaveData.reject) {
              // if error we don't have access to ticket instance
              slaveData.reject({ message, code });
              $log.error(`${LOG_PREFIX} Pay in rejected to slave`, {
                upstream_message: message,
                upstream_code: code,
                request_id: requestUuid,
                product_displayid: productDisplayId,
                code: 'T_INTEGRATOR_PAYIN_REJECTED'
              });
            }
          });
        break;
      case 'Tickets.Update':
        $rootScope.$emit('7T:Tickets.Update', slaveData.data);
        break;
      case 'ticket:addResponse': // this one should be deprecated in favor of Tickets.Update
        service = $injector.get('nabMessaging');
        service.publish('ticket:addResponse', slaveData.data);
        break;
      case 'ticket:cancelResponse': // this one should be deprecated in favor of Tickets.Update
        service = $injector.get('nabMessaging');
        service.publish('ticket:cancelResponse', slaveData.data);
        break;
      case 'ticket:payoutResponse': // this one should be deprecated in favor of Tickets.Update
        service = $injector.get('nabMessaging');
        service.publish('ticket:payoutResponse', slaveData.data);
        break;
      case 'Tickets.ValidatePayin':
        handleValidatePayin(slaveData);
        break;
      case 'Product:UpdateSettings':
      case 'Widget.UpdateSettings': // this one should be deprecated in favor of Widget.SettingsChanged
      case 'Widget.SettingsChanged':
        data = slaveData.data;
        service = $injector.get('SevenTicketConfig');
        service.setTicketConfig(slaveData.slaveId, data.ticketConfig);
        $rootScope.$broadcast('7T:Betslip.UpdateTicketConfig', {
          data: data.ticketConfig
        });
        break;
      case 'Slave.Event':
        e = createEvent(slaveData);
        if ($document[0].activeElement.nodeName !== 'IFRAME') {
          $document[0].activeElement.dispatchEvent(e);
        } else {
          $document[0].dispatchEvent(e);
        }
        break;
      case 'Tickets.Check':
        scanEventPubSub.publish(SCAN_EVENT_ID, slaveData);
        break;
      case 'UI.Show':
        handleRouteSwitch(slaveData);
        break;
      case 'Logger.Log':
        if (slaveData.data.level in logService) {
          logService[slaveData.data.level].apply(null, slaveData.data.payload);
        }
        break;
      default:
        $rootScope.$broadcast('7T:' + slaveData.action, slaveData);
      }
    });
  }

  function handleCustomerCardScan() {
    HooksManager.getHook('CustomerCardDetailsFetched').tap({
      name: 'CustomerCardDetailsFetched.handleCustomerCardScan',
      fn(params) {
        const code = params.cardData.card_serial;

        if (code) {
          Gateway.sendToAll({
            action: 'CustomerCard.Activated',
            enforceEvent: true,
            data: {
              card: {
                serial: code
              }
            }
          });
        }
      }
    });

    HooksManager.CustomerCardOptOut.tapPromise(
      { name: 'gravityGatewaySService.handleCustomerCardScan' },
      (params) => {
        const code = params.cardData.card_serial;

        Gateway.sendToAll({
          action: 'CustomerCard.Deactivated',
          enforceEvent: true,
          data: {
            card: {
              serial: code
            }
          }
        });

        return Promise.resolve();
      }
    );
  }

  return {

    /**
     *
     * @ngdoc method
     * @name 7Terminal.Integrator.services:GravityGatewayService#init
     * @methodOf 7Terminal.Integrator.services:GravityGatewayService
     *
     * @description
     * Init gateway Master instance with config and attach listeners.
     *
     */
    init: function () {
      Gateway = master({
        debug: logService.isDebugMode()
      });
      handleGatewaySubscription();

      handleCustomerCardScan();
    },

    /**
     * @ngdoc method
     * @name 7Terminal.Integrator.services:GravityGatewayService#initSlave
     * @methodOf 7Terminal.Integrator.services:GravityGatewayService
     *
     * @description
     * Init gateway Master instance with config and attach listeners.
     *
     * @param {Object} data - Configuration
     * @param {String} data.iframeSrc - Iframe URL
     * @param {String} data.iframeId - Iframe ID
     * @param {Object} data.iframeConfig - Iframe configuration
     * @param {Boolean} data.iframeConfig.destroy - Destory or keep iframe alive when Player leaves iframe screen
     * @param {Object} data.context - Data that will be sent in Gateway Load message
     * @returns {Promise} It will resolve when iframe is loaded and ready for message exchange
    */
    initSlave: function (data) {
      const iframeId = data.iframeId;
      const defer = $q.defer();
      let service = null;

      if (registeredModules[iframeId]) {
        if (destroyedIframes[iframeId]) {
          $timeout.cancel(destroyedIframes[iframeId]);
          delete destroyedIframes[iframeId];
        }

        defer.reject();
      } else {
        Gateway.addSlave({
          frameId: data.iframeId,
          slaveId: data.slaveId || data.iframeId,
          autoResize: false,
          data: data.context,
          init: angular.noop,
          loaded: function (slaveData) {
            registeredModules[data.iframeId] = data;
            if (slaveData?.data?.ticketConfig) {
              service = $injector.get('SevenTicketConfig');
              service.setTicketConfig(slaveData.slaveId, slaveData.data.ticketConfig);
              $rootScope.$emit('7T:Betslip.UpdateTicketConfig', {
                data: slaveData.data.ticketConfig
              });
            }

            if (slaveData?.data?.tax) {
              $rootScope.$emit('7T:Peripherals.UpdateConfig', {
                productId: slaveData.slaveId,
                data: {
                  printService: {
                    taxes: slaveData.data.tax
                  }
                }
              });
            }

            defer.resolve();
          }
        });
      }

      return defer.promise;
    },

    removeIframeOnDelay: (iframe) => {
      const destroyIframe = $timeout(() => {
        const iframeId = iframe.id;
        iframe.remove();
        Gateway.removeSlave(iframeId);
        delete registeredModules[iframeId];
        delete destroyedIframes[iframeId];
      }, 60000, false);
      destroyedIframes[iframe.id] = destroyIframe;
    },

    getDriver: function () {
      return Gateway;
    }
  };
}

export default GravityGatewayService;
