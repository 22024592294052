import { narApiService, narService } from '@/modules/nar';
import { type InstallerGetDataParams } from '../types';
import { DeviceInstaller } from '../interfaces/DeviceInstaller';

const DmInstaller: DeviceInstaller = {
  getData(params: InstallerGetDataParams) {
    return Promise.all([
      narApiService.getAppConfig(params),
      narApiService.getMasterInstanceData(),
    ]).then((res) => ({
      appConfig: {
        data: res[0].data,
      },
      device: {
        data: res[1].data,
      },
    })).finally(() => {
      narService.startNarKeepAlive();
    });
  },
  getCore() {
    return Promise.resolve();
  },
};

export {
  DmInstaller,
};
