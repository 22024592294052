<script setup lang="ts">
import '@nsftx/seven-components/style'; // main style
import '@nsftx/seven-components/palettes/default';
import '@nsftx/seven-components/palettes/yellow-balkan-bet';
import '@nsftx/seven-components/palettes/yellow-pionir';
import '@nsftx/seven-components/styles/components/terminal';
import { storeToRefs } from 'pinia';
import { computed, defineAsyncComponent } from 'vue';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import { useMarketingScreenSaverStore } from '@/modules/cms/marketing/marketingScreenSaverStore';
import { lobbyService } from '@/modules/lobby';
import TNotifications from '@/common/components/TNotifications.vue';
import WidgetsRenderer from '@/common/components/WidgetsRenderer.vue';
import { MaintenanceModal } from '@/modules/maintenance';
import SevenTicketPin from '@/modules/seven-ticket-security/SevenTicketPin.vue';
import router from './router';
import { isTboActive } from './common/services/helpers';

// Screen saver
const marketingScreenSaverStore = useMarketingScreenSaverStore();
const { isMarketingScreenSaverVisible } = storeToRefs(marketingScreenSaverStore);
const { isScreenSaverEnabled, showScreenSaver } = marketingScreenSaverStore;
const MarketingScreenSaver = defineAsyncComponent(() => import(
  /* webpackChunkName: "marketing_screen_saver_component" */
  '@/modules/cms/marketing/components/MarketingScreenSaver.vue'
));

// Lobby
const LobbyHome = defineAsyncComponent(() => import(
  /* webpackChunkName: "lobby_home_component" */
  '@/modules/lobby/components/LobbyHome.vue'
));

const IdleModalNotification = defineAsyncComponent(() => import(
  /* webpackChunkName: "idle_modal_notification" */
  '@/modules/inactivity-timer/components/IdleModalNotification.vue'
));

const showLobbyModal = computed(() => lobbyService.getIsModalShown());

const gravitySettingsStore = useGravitySettingsStore();
const palette = gravitySettingsStore.getModuleDataKeyValue('config', 'palette') || 'default';

const routerClasses = computed(() => {
  if (isTboActive()) {
    return 'top-0';
  }
  if (router.currentRoute.value.name === 'TicketCheck') {
    return 'top-128';
  }
  return 'top-64';
});

if (isScreenSaverEnabled()) {
  showScreenSaver();
}

document.documentElement.dataset.scChannel = 'terminal';
document.documentElement.dataset.scTheme = 'dark';
document.documentElement.dataset.scPalette = palette;

</script>

<template>
  <div
    id="vue-router"
    :class="routerClasses"
  >
    <router-view />
  </div>
  <LobbyHome v-if="showLobbyModal" />
  <TNotifications />
  <MarketingScreenSaver v-if="isMarketingScreenSaverVisible" />
  <WidgetsRenderer position="appModals" />
  <MaintenanceModal />
  <SevenTicketPin data-testid="SevenTicketPin" />
  <IdleModalNotification />
</template>

<style scoped lang="scss">
#vue-router {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 64px;
  z-index: 1;
}

.top-0 {
  top: 0;
}

.top-64 {
  top: 64px;
}

.top-128 {
  top: 128px;
}
</style>
