import * as Sentry from '@sentry/vue';
import { logService } from '@/common/services/logger';
import appTemplate from './app.html';
import coreModule from '../../core';
import commonModule from '../../common';
import installationModule from '../installation';
import runnerModule from '../runners';
import moduleModules from '../index';
import integratorModule from '../integrator';
import BootstrapController from './bootstrapController';

(function () {
  angular.module('7Terminal.Bootstrap', [
    'pascalprecht.translate',
    coreModule,
    commonModule,
    moduleModules,
    installationModule,
    runnerModule,
    integratorModule
  ]).config(function (
    $locationProvider,
    $httpProvider,
    $stateProvider,
    $urlRouterProvider,
    $translateProvider,
    $provide,
    EnvironmentProvider
  ) {
    $locationProvider.hashPrefix('');
    EnvironmentProvider.setLogLevel(EnvironmentProvider.detectLogLevel());
    logService.initLogger();
    logService.setLogLevel();

    $httpProvider.useApplyAsync(true);
    $httpProvider.defaults.headers.common['content-type'] = 'application/json';

    $httpProvider.defaults.withCredentials = true;
    $httpProvider.interceptors.push('nabHTTPInterceptor');
    // Interceptors cannot be lazy loaded in angular.js, so we need to register them on boot
    // and check inside interceptor wheather logic should be run or not.
    $httpProvider.interceptors.push('sevenInterceptor');

    $provide.decorator('$exceptionHandler', function exceptionHandlerDecroator(
      $delegate
    ) {
      // See https://github.com/angular/angular.js/blob/v1.4.7/src/minErr.js
      var angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;

      return function (ex, cause) {
        Sentry.withScope(function (scope) {
          if (cause) {
            scope.setExtra('cause', cause);
          }
          scope.addEventProcessor(function (event) {
            var exception = event.exception && event.exception.values && event.exception.values[0];
            var matches;

            if (exception) {
              matches = angularPattern.exec(exception.value || '');
              if (matches) {
                // This type now becomes something like: $rootScope:inprog
                exception.type = matches[1];
                exception.value = matches[2];
                event.message = exception.type + ': ' + exception.value;
                // auto set a new tag specifically for the angular error url
                event.extra = angular.extend({}, event.extra, { angularDocs: matches[3].substr(0, 250) });
              }
            }
            return event;
          });
          Sentry.captureException(ex);
        });
        $delegate(ex, cause);
      };
    });

    $translateProvider.translations(
      'local',
      {} // we do not have translation in this moment, they will be loaded after ShopBootstrap.boot is done
    );
    $translateProvider.useSanitizeValueStrategy(null);
    $translateProvider.preferredLanguage('local');
    $translateProvider.useLoader('lanugageLoader', {});

    $urlRouterProvider.otherwise('/');
    $stateProvider.state('app', {
      url: '/',
      templateUrl: appTemplate,
      controller: 'BootstrapController',
      controllerAs: 'vm'
    });
  }).controller('BootstrapController', BootstrapController)
    .run(function ($sniffer) {
      // use only hashbang mode, so vue can detect changes in router
      $sniffer.history = false;
    });
})();
