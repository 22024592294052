import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "t-footer-vue d-block sc-bg-500" }
const _hoisted_2 = { class: "float-left d-flex h-100 sc-px-3" }
const _hoisted_3 = { class: "sc-mr-3" }
const _hoisted_4 = { class: "t-footer-toggle" }
const _hoisted_5 = { class: "float-right d-flex h-100" }

import PubSub from 'pubsub-js';
import { getService } from '@/common/services/ngBridge';
import i18n from '@/plugins/i18n';
import { useConfigSettingsStore } from '@/modules/seven/config-settings';
import { localAppConfigService } from '@/common/services/local-app-config';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import { apiService as lpsSocketApiService } from '@/modules/lps-socket';
import TClock from '@/common/components/TClock.vue';
import InactivityBar from '@/common/components/InactivityBar.vue';
import TerminalInfo from '@/common/components/TerminalInfo.vue';
import { setIsModalShown, shouldOpenLobbyAfterIdleTime } from '@/modules/lobby/lobbyService';
import { useMarketingScreenSaverStore } from '@/modules/cms/marketing/marketingScreenSaverStore';
import { reloadIfAppOnHighMemoryUsage } from '@/modules/performance';
import TFooterActionsRight from './TFooterActionsRight.vue';
import TFooterActionsLeft from './TFooterActionsLeft.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TFooter',
  setup(__props) {

const configSettingsStore = useConfigSettingsStore();
const notificationsStore = useNotificationsStore();
const inactivityTime = configSettingsStore.data.terminalInactivityTime.value || 60000;
const { t } = i18n.global;

const onEndInactivityTimer = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $rootScope: any = getService('$rootScope');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loaderService: any = getService('loaderService');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const BetslipService: any = getService('BetslipService');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const switchView: any = getService('switchView');
  const { isScreenSaverEnabled, showScreenSaver } = useMarketingScreenSaverStore();

  // Close numpad
  $rootScope.$emit('closeNumPad:stop', '');
  // Stop loader
  loaderService.endLoader();
  // Emit end of session activity
  PubSub.publish('activity.sessionEnd');
  // Reset every game betslip
  BetslipService.resetBetslip();
  // Notify NAR if infoPoint mode is active
  if (localAppConfigService.data.infoPointActive) {
    lpsSocketApiService.dispatchInfoPointActivation();
  }
  // Go back to landing page
  switchView.goToLandingPage(true);

  // Scroll to top after inactivity timer ends
  const homeEl = document.getElementById('home-page');
  homeEl?.scrollTo({ behavior: 'smooth', top: 0 });

  if (shouldOpenLobbyAfterIdleTime()) {
    setIsModalShown(true);
  }

  if (isScreenSaverEnabled()) {
    showScreenSaver();
  }

  reloadIfAppOnHighMemoryUsage();

  notificationsStore.show({
    message: t('notifications_landing_page_inactivity'),
    type: TNotificationTypeEnum.info,
    delay: 3000,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(InactivityBar, {
          name: "terminal-inactivity",
          orientation: "vertical",
          "on-end-inactivity-timer": onEndInactivityTimer,
          "transition-delay": 5000,
          time: _unref(inactivityTime)
        }, null, 8 /* PROPS */, ["time"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(TerminalInfo, { class: "sc-mr-3" })
      ]),
      _createVNode(TFooterActionsLeft)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(TFooterActionsRight),
      _createVNode(TClock)
    ])
  ]))
}
}

})