import i18n from '@/plugins/i18n';
import { BindUserCardData, UserCardDetails } from '@/modules/user/types';
import { cardReaderService, useCardReaderStore, type CardDataInfo } from '@/modules/card-reader';
import { UserApi } from '@/modules/user';
import { TNotificationTypeEnum, useNotificationsStore } from '@/common/stores/notifications';
import HooksManager from '@/common/services/HooksManager';
import BaseError from '@/common/errors/BaseError';
import { useCardOperationsStore } from './store';

const { t } = i18n.global;

const addCheckBeforeLogout = (): void => {
  let notification: any = null;
  const notificationsStore = useNotificationsStore();
  const cardReaderStore = useCardReaderStore();

  HooksManager.BeforeOperatorLogout
    .tapPromise(
      { name: 'BeforeOperatorLogout.CardOperations' },
      (params) => new Promise<any>(
        (resolve, reject) => {
          const logoutTimer = setTimeout(() => {
            if (notification) notificationsStore.removeNotification(notification);
            params.onLogout({ force: true });
          }, 3000);
          if (cardReaderStore.isContactCard() && cardReaderStore.isCardIn()) {
            notification = notificationsStore.show({
              message: t('notifications.remove_card'),
              type: TNotificationTypeEnum.info,
              delay: null,
              actions: [{
                callback() {
                  clearTimeout(logoutTimer);
                  params.onLogout({ force: true });
                },
                text: t('actions.proceed'),
              }],
            });
            reject({
              message: t('notifications.remove_card'),
              code: 'REMOVE_CARD_ERROR',
            });
          }
          resolve(true);
        },
      ),
    );
};

const getUserByUuid = (uuid: string, auth?: {
  hasAuth: 1 | 0
}) => UserApi.getUserByUuid(uuid, auth || {});

const formatCardCallbackFn = () => {
  const notificationsStore = useNotificationsStore();

  cardReaderService.formatCard().then(() => {
    notificationsStore.show({
      message: t('notifications.card_format_success'),
      type: TNotificationTypeEnum.success,
      delay: 3000,
    });
  }).catch(() => {
    notificationsStore.show({
      message: t('notifications.card_format_failed'),
      type: TNotificationTypeEnum.warning,
      delay: 3000,
    });
  });
};

const getOperators = (searchText?: { search: string }) => UserApi.getOperators(searchText);

const getLastSearch = (): Promise<void> => {
  const cardOperationsStore = useCardOperationsStore();
  if (cardOperationsStore.searchParams.type === 'card') {
    return getUserByUuid(cardOperationsStore.searchParams.card).then((response) => {
      cardOperationsStore.updateUserList(response.data);
    });
  }
  return getOperators(cardOperationsStore.searchParams.text.params).then((response) => {
    cardOperationsStore.updateUserList(response.data);
  });
};

const handleCardInsertion = (data: CardDataInfo | null): Promise<void> => {
  const notificationsStore = useNotificationsStore();
  const userUuid: string = data?.uuid || '';
  if (data?.cardEmpty) {
    notificationsStore.show({
      message: t('notifications.card_empty'),
      type: TNotificationTypeEnum.warning,
      delay: 3000,
    });
    const error = new BaseError(
      t('notifications.card_empty'),
      'T_TBO_OPERATOR_CARD_EMPTY_ERR',
    );
    return Promise.reject(error);
  }

  return getUserByUuid(userUuid, { hasAuth: 1 }).then((userResponse) => {
    const cardOperationsStore = useCardOperationsStore();
    cardOperationsStore.updateSearchParams('card', { search: userUuid });
    // If submodule is active
    if (cardOperationsStore.operatorPermissions.operatorCards.active) {
      const canViewOperators = cardOperationsStore.operatorPermissions
        .operatorCards.actions?.viewOperators;
      cardOperationsStore.updateUserList(userResponse.data);

      if (canViewOperators) {
        notificationsStore.show({
          message: t('customer_cards_operator_card_owner_details', { name: userResponse.data.grid.data[0].firstName }),
          type: TNotificationTypeEnum.info,
          id: 'valid_card_data',
          delay: null,
          actions: [{
            callback() {
              formatCardCallbackFn();
            },
            text: t('actions.format_card'),
          }],
        });
        return Promise.resolve();
      }
    }
    const error = new BaseError(
      'Operator card has no permission',
      'T_TBO_OPERATOR_CARD_NO_PERMISSION_ERR',
    );
    return Promise.reject(error);
  }).catch((err) => {
    notificationsStore.show({
      message: t('notifications.invalid_card_data'),
      type: TNotificationTypeEnum.info,
      id: 'invalid_card_data',
      delay: null,
      actions: [{
        callback() {
          formatCardCallbackFn();
        },
        text: t('actions.format_card'),
      }],
    });
    return Promise.reject(err);
  });
};

const unbindCard = (data: UserCardDetails) => UserApi.unbindCard(data);

const bindCard = (data: BindUserCardData) => UserApi.bindCard(data);

const lockCard = (data: UserCardDetails) => UserApi.lockCard(data);

const unlockCard = (data: UserCardDetails) => UserApi.unlockCard(data);

const handleTboCardReadEvent = (
  data: CardDataInfo,
): Promise<void> => handleCardInsertion(data);

const init = (data: any): void => {
  const cardOperationsStore = useCardOperationsStore();

  addCheckBeforeLogout();
  if (!data) {
    return;
  }

  cardOperationsStore.populateOperatorPermissions(data);
};

export {
  init,
  getLastSearch,
  getUserByUuid,
  handleCardInsertion,
  bindCard,
  unbindCard,
  lockCard,
  unlockCard,
  getOperators,
  handleTboCardReadEvent,
};
