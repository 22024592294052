import axios from 'axios';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import i18n from '@/plugins/i18n';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import { serviceModeHandler } from '@/modules/service-mode';
import { betslipApiHelpers } from '@/modules/seven-betslip-api';
import { voucherManager } from '@/modules/vouchers';
import { useTicketCheckStore } from '../ticketCheckStore';
import ticketCheckStrategyService from './ticketCheckStrategyService';
import { CLOSE_TICKET_CHECK_MODALS_ID, closeTicketCheckModalsEventPubSub } from '../events';

const LOG_PREFIX = '[ticketCheckService]';
const NOTIFICATION_ID = 'ticket-check-validation-error';
const { t } = i18n.global;

const validateTicketCheck = (ticketId: string) => {
  const notificationsStore = useNotificationsStore();
  notificationsStore.closeNotificationWithId(NOTIFICATION_ID);

  if (serviceModeHandler.isServiceModeActive()) {
    logService.warn(`${LOG_PREFIX} Service mode is active. Ticket check cannot be done`, {
      code: 'T_TICKET_CHECK_SERVICE_MODE_ACTIVE',
      ticket_code: ticketId,
    });

    notificationsStore.show({
      message: t('settings.service_active_warning'),
      type: TNotificationTypeEnum.warning,
      delay: null,
      id: NOTIFICATION_ID,
    });
    return false;
  }

  if (voucherManager.isVoucherCode(ticketId)
        && voucherManager.checkIsVoucherPayoutDisabled()) {
    logService.warn(`${LOG_PREFIX} Voucher payout is disabled.`, {
      code: 'T_VOUCHER_PAYOUT_DISABLED',
      ticket_code: ticketId,
    });
    notificationsStore.show({
      message: t('voucher_payout_disabled'),
      type: TNotificationTypeEnum.error,
      id: NOTIFICATION_ID,
      delay: 3000,
    });
    return false;
  }

  return true;
};

const cleanUpCheckData = () => {
  const ticketCheckStore = useTicketCheckStore();

  ticketCheckStore.clearActions();
  ticketCheckStore.clearResult();
};

const checkTicketCode = (ticketId: string) => {
  const notificationsStore = useNotificationsStore();

  closeTicketCheckModalsEventPubSub.publish(CLOSE_TICKET_CHECK_MODALS_ID, { reason: 'New ticket is checked' });

  if (!validateTicketCheck(ticketId)) {
    return;
  }

  cleanUpCheckData();

  const detectedStrategyData = ticketCheckStrategyService
    .getTicketCheckStrategyDetails(ticketId);

  if (!detectedStrategyData) {
    logService.warn(`${LOG_PREFIX} Strategy not found for ticket id. Ticket check cannot be done.`, {
      code: 'T_TICKET_CHECK_STRATEGY_NOT_FOUND',
      ticket_code: ticketId,
    });
    return;
  }

  const { strategyService, productId, name } = detectedStrategyData;
  const hasTicketPin = betslipApiHelpers.hasTicketPin(ticketId);
  const codeWithoutPin = hasTicketPin ? betslipApiHelpers
    .stripTicketPin(ticketId) : ticketId;

  strategyService.checkTicketCode({
    code: codeWithoutPin,
    productId,
  }).then((res) => {
    if (res) {
      const ticketCheckStore = useTicketCheckStore();
      ticketCheckStore.setId(ticketId);
      ticketCheckStore.setDetectedStrategy(name);
      ticketCheckStore.setResult(res.ticket);
    }
  }).catch((error) => {
    const isAxiosError = axios.isAxiosError(error);
    const parser = isAxiosError ? axiosErrorParser : errorParser;
    const details = parser.parseUpstream(error);

    logService.warn(`${LOG_PREFIX} Failed to check ticket.`, {
      code: 'T_TICKET_CHECK_FAILED',
      ...details,
      ticket_code: ticketId,
      product_displayid: productId,
      strategy: name,
    });

    notificationsStore.show({
      message: details.upstream_message,
      type: TNotificationTypeEnum.warning,
      delay: 3000,
    });
  });
};

export default {
  checkTicketCode,
};
