import BaseError from '@/common/errors/BaseError';
import { type DmCoreErrorType } from '../types/dmCoreTypes';

export default class DmCoreError extends BaseError<DmCoreErrorType> {
  constructor(
    public message: string,
    public code: DmCoreErrorType,
    public options: {
      cause: unknown;
    },
  ) {
    super(message, code, options);
    Object.setPrototypeOf(this, DmCoreError.prototype);
    this.name = this.constructor.name;
  }
}
