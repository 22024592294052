/* eslint-disable no-console */
import { Core, Display, DMStateData } from '@nsftx/systems-sdk';
import { axiosErrorParser, errorParser } from '@/common/services/error-parser';
import { logService } from '@/common/services/logger';
import DMGetDataResponse from './interfaces/DMGetDataResponse';
import DMGetApplicationData from './interfaces/DMGetApplicationData';
import DMGetApplicationConfigResponse from './interfaces/DMGetApplicationConfigResponse';
import { getAppConfigData } from './apiService';
import DmConfigDataError from './errors/DmConfigDataError';
import DmCoreError from './errors/DmCoreError';

// Vars
let core: Core;
let isConnected = false;

const init = () => {
  core = new Core('7Terminal');
  isConnected = false;
};

/**
 * @throws {DmCoreError}
 */
const getCore = (): Promise<Core> => new Promise((resolve, reject) => {
  if (isConnected) {
    resolve(core);
    return;
  }

  init();
  core.connect().catch((error: unknown) => {
    reject(new DmCoreError(
      errorParser.parseMessage(error),
      'T_DEVICE_MANAGEMENT_CORE_CONNECTION_ERROR',
      {
        cause: error,
      },
    ));
  });

  core.on('connection', () => {
    isConnected = true;
    resolve(core);
  });
});

const getApplicationConfigUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const appRunUuid = urlParams.get('appRunUuid') || '';
  const display = new Display(core);

  return display.getApplication(appRunUuid);
};

/**
 * @throws {DmConfigDataError}
 */
const getApplicationConfig = (): Promise<DMGetApplicationConfigResponse> => new Promise(
  (resolve, reject) => {
    getApplicationConfigUrl()
      .then((data: string) => {
        const parsedData: DMGetApplicationData = JSON.parse(data);
        if (!parsedData.config_url) {
          reject(new DmConfigDataError(
            'Config url is empty',
            'T_DEVICE_MANAGEMENT_DISPLAY_DATA_CONFIG_URL_EMPTY',
          ));
          return;
        }

        getAppConfigData(parsedData.config_url)
          .then((res) => {
            resolve(res.data);
          }).catch((err) => {
            const parsedAxiosErr = axiosErrorParser.parseUpstream(err);
            logService.error('[deviceManagementService] Failed to fetch application config data', {
              code: 'T_DEVICE_MANAGEMENT_CONFIG_DATA_FETCH_FAILED',
              ...parsedAxiosErr,
            });
            reject(new DmConfigDataError(
              'Failed to fetch application config data',
              'T_DEVICE_MANAGEMENT_CONFIG_DATA_FETCH_FAILED',
              {
                cause: err,
              },
            ));
          });
      })
      .catch(() => {
        reject(new DmConfigDataError(
          'Failed to fetch application config url',
          'T_DEVICE_MANAGEMENT_CONFIG_URL_FETCH_FAILED',
        ));
      });
  },
);

const getData = (): Promise<DMGetDataResponse> => new Promise((resolve, reject) => {
  getCore().then(() => {
    // if we don't receive data from DM in 5 seconds, reject the bootstrap request
    const getDataTimer = setTimeout(() => {
      const error = new Error('Failed to fetch data from DM runtime.');
      error.name = 'T_DEVICE_MANAGEMENT_DATA_FETCH_FAILED';
      reject(error);
    }, 5000);

    return Promise.all([
      getApplicationConfig(),
      core.deviceState(),
    ])
      .then((data) => {
        clearTimeout(getDataTimer);
        const appConfigData: DMGetApplicationConfigResponse = data[0];
        const deviceStateData: DMStateData = data[1];

        resolve({
          device: {
            data: {
              device: {
                name: deviceStateData.device.name,
                uuid: deviceStateData.device.uuid,
                deviceId: deviceStateData.device.device_id,
              },
              betshop: {
                betshopId: deviceStateData.location.betshop_id,
                companyId: deviceStateData.space.company_id,
                uuid: deviceStateData.location.uuid,
                name: deviceStateData.location.name,
                companyName: deviceStateData.space.name,
                companyUuid: deviceStateData.space.uuid,
              },
            },
          },
          appConfig: {
            data: {
              appConfig: {
                terminalAccount: {
                  uuid: appConfigData.app_config.terminal_account.uuid,
                  password: appConfigData.app_config.terminal_account.password,
                },
              },
              sevenUrl: appConfigData.seven_url,
              sevenSocketUrl: appConfigData.seven_socket_url,
            },
          },
        });
      })
      .catch(reject);
  });
});

export default {
  init,
  getCore,
  getData,
  getApplicationConfigUrl,
  getApplicationConfig,
};
