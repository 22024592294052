import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { SevenComponentsPlugin } from '@nsftx/seven-components';
import { translationPlugin } from '@/plugins/i18n';
import { initSentry } from '@/modules/sentry';
import App from './App.vue';

import router from './router';

const app = createApp(App);
app.use(createPinia());
initSentry(app, router);
app.use(router);
app.use(translationPlugin);
app.use(SevenComponentsPlugin, { SCKeyboard: { bottom: '64px' } });

export default app;
