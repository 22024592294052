import axios from 'axios';
import Logger, { ILogLevel } from 'js-logger';
import { useSevenStore } from '@/modules/seven';
import { useENVStore } from '@/common/stores/env';
import { useGravitySettingsStore } from '@/modules/cms/gravity-settings';
import type {
  SevenLogLevel,
  SevenLog,
  SevenLogData,
  LocalLog,
} from './index';

let intervalInstance: number | undefined;

let logs: Array<SevenLog> = [];

let currentLogLevel = 0;

let isLoggerSet = false;

let additionalLogData: SevenLogData = {};

const LOG_LEVELS: Record<string, number> = {
  debug: 2,
  info: 3,
  warn: 5,
  error: 8,
};

// temporary fix until we remove NPS
const blacklistMessages = [
  '[NgPrintService] selectPrinter startPrinterChecker failed',
  '[NgPrintService] selectPrinter stop checker failed',
];

const sendLogsToGraylog = () => {
  const sevenStore = useSevenStore();
  const envStore = useENVStore();
  const { tenant, betshop, device } = sevenStore;
  const tempLogs = logs.map((log) => {
    const sevenLog: SevenLog = {
      ...log,
      channel: '7terminal',
      appName: localStorage.getItem('window.title'),
      tenant_id: tenant.uuid,
      tenant_name: tenant.name,
      betshop_id: betshop.uuid,
      betshop_name: betshop.name,
      device_id: device.uuid,
      device_name: device.name,
      version: envStore.data.client_version,
      http_user_agent: window.navigator.userAgent,
    };
    return sevenLog;
  });
  logs = [];
  return axios.post(`${process.env.GRAPE_API_URL}/bulk_logs`, JSON.stringify(tempLogs), {
    headers: {
      Authorization: `Basic ${btoa(`${process.env.GRAPE_API_AUTH_USER}:${process.env.GRAPE_API_AUTH_PASS}`)}`,
      'Content-Type': 'application/json',
    },
  }).catch((err) => {
    // we need to catch this call or sentry will report error,
    // let's log error to console so we dont miss it in development/qa/production
    // eslint-disable-next-line no-console
    console.error('[logService] Send logs to graylog error', err);
  });
};

const isDebugMode = (): boolean => currentLogLevel === LOG_LEVELS.debug;

const consoleHandler = Logger.createDefaultHandler();

const sendLogsOnMessageLimit = (log: Array<SevenLog>): void => {
  if (blacklistMessages.indexOf(log[1].message) !== -1) {
    return;
  }

  logs.push(log[1]);
  if (logs.length >= parseInt(process.env.GRAPE_MESSAGE_LIMIT, 10)) {
    sendLogsToGraylog();
  }
};

const startSendLogsInIntervals = (): void => {
  if (intervalInstance) {
    clearInterval(intervalInstance);
  }

  intervalInstance = window.setInterval(() => {
    if (logs.length > 0) {
      sendLogsToGraylog();
    }
  }, parseInt(process.env.GRAPE_INTERVAL_LIMIT, 10));
};

const setLoggerLevel = (level: ILogLevel) => {
  Logger.setLevel(level);
};

// eslint-disable-next-line max-len
const sendLog = ({ message, data }: { message: string, data?: SevenLogData }, level: SevenLogLevel): void => {
  const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
  const localTime = (new Date(Date.now() - timeZoneOffset));
  const validMessage = message || 'T_NO_MESSAGE_PROVIDED';
  let log: LocalLog = {
    message: validMessage,
    level,
    timestamp: localTime.toISOString(),
  };

  if (typeof data === 'object' && !Array.isArray(data)) {
    log = { ...log, ...data, ...additionalLogData };
  }

  if (log.upstream_code) {
    log.upstream_code = log.upstream_code.toString();
  }

  Logger[level](validMessage, log);
};

const getUrlParameter = (name: string): string => {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getLogLevel = (logLevel: string): number => LOG_LEVELS[logLevel] || LOG_LEVELS.error;

const detectLogLevel = (): string => {
  const debugModeParam = getUrlParameter('debugMode');
  const ls = localStorage.getItem('settings.logLevel');
  const qp = getUrlParameter('logLevel');

  let gravitySettingsStore;
  let logLevel = 'info';

  if (isLoggerSet) {
    gravitySettingsStore = useGravitySettingsStore();
    logLevel = gravitySettingsStore.getModuleDataKeyValue('application', 'logLevel');
  }

  if (debugModeParam) {
    return 'debug';
  }

  if (ls === null && (qp === 'false' || qp === '' || qp === 'undefined')) {
    return logLevel;
  }

  return ls || qp;
};

const onRightClick = (event: Event): void => event.preventDefault();

// When our stores are not yet initialzed, our default logLevel = OFF - meaning our logger will
// only work once pinia/stores are initialzed (on boot). However this is a problem, because we
// have many services that run before that so they will never work with logService.
// That's why we need this fn to set default logLevel before we even have any data from CMS .e.g
// Also, we need to call this function immediately once this file gets imported.
const setDefaultLogLevel = (): void => {
  const logLevel = detectLogLevel();

  Logger.useDefaults({
    defaultLevel: {
      value: getLogLevel(logLevel),
      name: logLevel.toUpperCase(),
    },
  });
};

setDefaultLogLevel();

const setLogLevel = (): void => {
  const logLevel = detectLogLevel();
  currentLogLevel = getLogLevel(logLevel);
  if (currentLogLevel > LOG_LEVELS.debug) {
    // if in not debug mode prevent right click (blocks inspect mode)
    window.addEventListener('contextmenu', onRightClick);
  } else {
    window.removeEventListener('contextmenu', onRightClick);
  }

  setLoggerLevel(
    {
      value: currentLogLevel,
      name: logLevel?.toUpperCase(),
    },
  );
};

const setAdditionalLogData = (data: SevenLogData) => {
  additionalLogData = { ...additionalLogData, ...data };
};

const initLogger = () => {
  startSendLogsInIntervals();

  // We need to call initLogger method twice because of CMS settings
  // are loaded afterwards. So to prevent calling Logger.setHandler twice,
  // that's why this logic with isLoggerSet.
  if (!isLoggerSet) {
    isLoggerSet = true;
    Logger.setHandler((log, context) => {
      consoleHandler(log, context);
      sendLogsOnMessageLimit(log);
    });
  }
};

const logService = {
  error: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'error');
  },
  warn: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'warn');
  },
  info: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'info');
  },
  debug: (message: string, data?: SevenLogData) => {
    sendLog({ message, data }, 'debug');
  },
  sendLogsToGraylog,
  setLoggerLevel,
  sendLog,
  initLogger,
  isDebugMode,
  setLogLevel,
  detectLogLevel,
  setAdditionalLogData,
};

export default logService;
