import { ref } from 'vue';
import { defineStore } from 'pinia';
import { filter } from 'underscore';
import { logService } from '@/common/services/logger';
import { type Widget, WidgetSourceType } from './types';

const LOG_PREFIX = '[widgetsStore]';

const useWidgetsStore = defineStore('widgets', () => {
  const widgets = ref<Array<Widget>>([]);

  const isAlreadyRegistered = (widget: Widget): boolean => widgets.value
    .findIndex(({ name }) => name === widget.name) !== -1;

  const registerWidget = (newWidget: Widget): boolean => {
    const widget = newWidget;
    if (widget.active === false) {
      logService.warn(`${LOG_PREFIX} Widget is not active!`, {
        widget,
        code: 'T_WIDGET_NOT_ACTIVE',
      });
      return false;
    }

    if (isAlreadyRegistered(widget)) {
      logService.warn(`${LOG_PREFIX} Widget is already registered!`, {
        widget,
        code: 'T_WIDGET_ALREADY_REGISTERED',
      });
      return false;
    }

    if (!widget.source) {
      logService.error(`${LOG_PREFIX} Widget source definition missing!`, {
        widget,
        code: 'T_WIDGET_SOURCE_MISSING',
      });
    }

    if (widget.shouldRender === undefined) {
      widget.shouldRender = true;
    }

    if (!widget.order) {
      widget.order = 100;
    }

    widgets.value.push(widget);

    if (!Object.keys(WidgetSourceType).includes(widget.source.type)) {
      logService.error(`${LOG_PREFIX} Widget type unknown!`, {
        widget,
        code: 'T_WIDGET_TYPE_UNKNOWN',
      });
      return false;
    }

    return true;
  };

  const loadGravityWidgets = (config: Record<string, any>) => {
    const foundWidgets = filter(config, (value, key) => key.startsWith('widget.'));

    foundWidgets.forEach((widget) => {
      registerWidget(widget);
    });
  };

  const getWidgetByType = (types: Array<string>, whenValue: string): Widget | undefined => {
    const result = widgets.value.find((widget) => types.includes(widget.type || '')
      && widget.when?.values?.includes(whenValue)
      && widget.active === true);

    return result;
  };

  const getWidgetsByPosition = (position: string): Array<Widget> => widgets.value
    .filter((widget) => {
      if (widget.positions && widget.shouldRender) {
        return widget.positions.some((pos) => pos.id === position);
      }
      return false;
    }).sort((n, m) => n.order - m.order);

  const getWidgetByName = (name: string): Widget | undefined => widgets.value
    .find((widget) => widget.name === name);

  const getWidgetByFrameId = (frameId: string): Widget | undefined => widgets.value
    .find((widget) => widget.source.config?.frameId === frameId);

  const setRender = (name: string, value: boolean) => {
    widgets.value = widgets.value.map((widget) => {
      if (widget.name === name) {
        return { ...widget, shouldRender: value };
      }
      return widget;
    });
  };

  return {
    widgets,
    registerWidget,
    loadGravityWidgets,
    getWidgetByType,
    getWidgetsByPosition,
    getWidgetByName,
    getWidgetByFrameId,
    setRender,
  };
});

export {
  useWidgetsStore,
};
