import axios from 'axios';
import { debounce } from 'underscore';
import i18n from '@/plugins/i18n';
import { SevenApiRoutes } from '@/modules/seven';
import HooksManager from '@/common/services/HooksManager';
import { logService } from '@/common/services/logger';
import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler/';
import { useNotificationsStore, TNotificationTypeEnum } from '@/common/stores/notifications';
import AppMode from '@/common/enums/TerminalApps';
import { safeAppReload } from '@/common/services/appHelpersService';
import type { OperatorData, LogoutOptions } from './types';

const data: OperatorData = {} as OperatorData;
const { t } = i18n.global;

const catchErr = (err: any): void => {
  logService.error('[operatorService] BeforeOperatorLogout error detected', {
    ...err,
    code: 'T_TBO_BEFORE_OPERATOR_LOGOUT_ERROR',
  });
};

const setData = (_data: OperatorData): void => {
  Object.assign(data, _data);
};

const onLogout = () => axios.get(`${SevenApiRoutes.routes.mainUrl}/logout`).finally(() => {
  localStorage.removeItem('user.token');
  safeAppReload();
});

const logout = (options?: LogoutOptions): void => {
  // eslint-disable-next-line no-param-reassign
  options = options || { force: false };

  if (!options.force) {
    HooksManager.BeforeOperatorLogout.promise({ onLogout })
      .then(onLogout)
      .catch(catchErr);
  } else {
    onLogout().catch(catchErr);
  }
  // clear data object
  Object.keys(data as OperatorData).forEach((key) => {
    delete data[key as keyof typeof data];
  });
};

const isLoggedIn = () => Object.keys(data).length !== 0;

// show notifications when operator permissions have been changed
const showPreLogoutNotification = (messageKey: string) => {
  const notificationsStore = useNotificationsStore();
  const action = [{
    callback() {
      logout();
    },
    text: t('actions.ok'),
  }];

  notificationsStore.show(
    {
      message: t(messageKey),
      type: TNotificationTypeEnum.warning,
      uiBlocking: true,
      id: 'prelogout_notification',
      actions: action,
      delay: null,
      closeDisabled: true,
    },
  );
};

const registerEvents = () => {
  const envHandlerStore = useEnvironmentHandlerStore();

  PubSub.subscribe('NCM:disabledUser', () => {
    showPreLogoutNotification('user.disabled_user_message');
  });

  PubSub.subscribe('Operator.RoleChanged', () => {
    const debouncedShowPreLogoutNotification = debounce(showPreLogoutNotification, 200);
    debouncedShowPreLogoutNotification('user.changed_permissions_message');
  });

  PubSub.subscribe('NCM:betshopRolesChanged', () => {
    if (AppMode.BACK_OFFICE === envHandlerStore.getState('activeApp')) {
      showPreLogoutNotification('user.changed_permissions_message');
    }
  });
};

const init = (_data: OperatorData) => {
  registerEvents();
  setData(_data);
};

export default {
  data,
  init,
  isLoggedIn,
  setData,
  logout,
};
