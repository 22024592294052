import { scanEventPubSub, SCAN_EVENT_ID } from '@/modules/handheld-scan';

/**
  * @ngdoc service
  * @name 7Terminal.Core.services:ScanService
  *
  * @description
  * This service manages payment methods.
  *
* */

/* @ngInject */
function ScanService($window, $rootScope, $log) {
  const config = {
    treshold: 30,
    prefix: 'space',
    regex: {
      pattern: /^[A-Za-z0-9.\-%]$/,
      flags: 'i'
    }
  };
  const scanResult = {
    code: '',
    finished: false
  }; // Code to be passed to ticket check
  let currentTime = 0; // time current key is received (time based scanning)
  const previousKey = {
    receivedAt: null,
    event: null
  };
  let previousEventReceived = Date.now(); // time when previous event was received (time based scanning)
  let difference = 0;

  function isPrefixBased(e) {
    return e.code?.toLowerCase() === config.prefix;
  }

  function processKeyEvent(e) {
    const whitelistedKeys = new RegExp(config.regex.pattern, config.regex.flags); // Array of key codes whose values wont't be concat with ticketId (enter, shift, space, arrow down)
    const isPrefixTriggered = isPrefixBased(e);

    currentTime = Date.now();
    difference = currentTime - previousEventReceived;
    previousEventReceived = currentTime;
    $log.debug(`[7Terminal.Core] Received key ${e.key}`);

    // Too much difference between characters - which means that this is not scan mode.
    // First time, difference is equal to current time, so we will extract that from check.
    // We also prevent scan trigger if somone holds key (e.repeat).
    // Last flag is when space is trigered as first char, we want to enter in scan mode
    if ((difference > config.treshold
      && difference !== currentTime
      && !isPrefixTriggered)
      || e.repeat) {
      $log.debug('[7Terminal.Core] It`s not scan mode. Reseting ...', {
        key: e.key,
        keyCode: e.keyCode,
        difference,
        currentTime,
        previousEventReceived
      });
      scanResult.code = '';
      previousKey.receivedAt = currentTime;
      previousKey.event = e;
      scanResult.finished = false;
      return scanResult;
    }

    // let's add previous char to list of scanned codes if time passed
    // before previous and current is below treshold
    // this will happen if scan barcode without space prefix
    if (scanResult.code.length === 0
      && !isPrefixTriggered
      && (previousKey.event && (currentTime - previousKey.receivedAt) < (config.treshold * 2))
      && whitelistedKeys.test(previousKey.event.key)
    ) {
      scanResult.code += previousKey.event.key;
      previousKey.event = null;
      previousKey.receivedAt = 0;
    }

    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();

    // we need moove focus out of any input to body so we don't enter
    // codes from scaner but we don't know if first char is from scan so don't move focus when first code is entered
    if (scanResult.code.length !== 0
        && $window.document.activeElement?.tagName.toLocaleLowerCase() !== 'body') {
      $window.document.activeElement.blur();
    }

    if (whitelistedKeys.test(e.key)) {
      scanResult.code += e.key;
      previousKey.event = null;
    }

    if (e.key.toLowerCase() === 'enter') {
      previousEventReceived = 0;

      if (scanResult.code.length) {
        scanResult.finished = true;
      }
    }
    return scanResult;
  }

  function timeBasedScanning(e) {
    var result = processKeyEvent(e);
    if (result.finished) {
      const eventData = {
        callSign: {
          sufix: ''
        },
        type: 'barcode',
        data: {
          code: result.code
        }
      };

      /**
     * @ngdoc event
     * @name 7Terminal.Core.services:ScanService#7T:Scanner.ScanningFinished
     * @eventOf 7Terminal.Core.services:ScanService
     * @eventType emit on scanned code
     *
     * @description Fired when hand held barcode scanner finishes with scanning
     * @param {Object} eventData
     * @param {Object} eventData.callSign
     * @param {String} eventData.callSign.sufix
     * @param {String} eventData.type
     * @param {Object} eventData.data
     * @param {String} eventData.data.code Result of scan
     */
      scanEventPubSub.publish(SCAN_EVENT_ID, eventData);
      scanResult.code = '';
      scanResult.finished = false;
    }
  }

  function listenForScannerEvents() {
    $window.addEventListener('keydown', timeBasedScanning, true);
  }

  $rootScope.$on('$destroy', () => {
    // remove event when module or app destroyed,
    // usefull in tests
    stopListeningForScannerEvents();
  });

  function stopListeningForScannerEvents() {
    $window.removeEventListener('keydown', timeBasedScanning, true);
  }

  return {
    listenForScannerEvents,
    stopListeningForScannerEvents
  };
}

export default ScanService;
