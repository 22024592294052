import axios, { AxiosError } from 'axios';
import { Pocket } from '@nsftx/systems-sdk';
import { localAppConfigService } from '@/common/services/local-app-config';

import { useEnvironmentHandlerStore } from '@/common/stores/environment-handler';
import deviceManagementService from '@/modules/device-management/deviceManagementService';

import {
  AcceptorsTransactionStatus,
  AcceptorName,
  type AcceptorTransaction,
  type AcceptorAppoensReportResponse,
  type AcceptorType,
  type TerminalAcceptorTransaction,
  type AcceptorTransactionHistoryResponse,
} from './types';

const CONFIG: {
  billUrl: string,
  coinUrl: string
} = {
  billUrl: '',
  coinUrl: 'http://127.0.0.1:8079',
};

const formatTransaction = (transaction: AcceptorTransaction): TerminalAcceptorTransaction => {
  const newTransaction: TerminalAcceptorTransaction = {
    time: transaction.time,
  } as TerminalAcceptorTransaction;
  newTransaction.value = parseFloat(transaction.value.toString()).toFixed(2);
  newTransaction.device = AcceptorName[transaction.device as keyof typeof AcceptorName];

  // format status
  if (transaction.status
    && Object.keys(AcceptorsTransactionStatus).includes(transaction.status.toUpperCase())) {
    newTransaction.status = AcceptorsTransactionStatus[
      transaction.status.toUpperCase() as keyof typeof AcceptorsTransactionStatus
    ];
  }

  // format date,
  // date is in format is 'YYYY-MM-DD HH:MM:SS' or timestamp (1489482182472)
  newTransaction.timeObject = new Date(
    typeof transaction.time === 'number' ? transaction.time : Date.parse(transaction.time.toString().replace('/-/g', '/')),
  );
  return newTransaction;
};

/**
   * Get list of apoens inserted into cash box
   */
const getAcceptorApoensReport = () => axios.get<AcceptorAppoensReportResponse>(`${CONFIG.billUrl}/getAcceptorApoensReport`);

/**
   * Get list of transaction made with acceptor (bill and coin)
   */
const getAcceptorTransactionHistory = () => axios.get<AcceptorTransactionHistoryResponse>(`${CONFIG.billUrl}/getAcceptorTransactionHistory`)
  .then((response) => {
    const { data } = response;
    let modifiedTransactions: Array<TerminalAcceptorTransaction> = [];

    if (data?.transactions?.length) {
      modifiedTransactions = data.transactions
        .map((transaction: AcceptorTransaction) => formatTransaction(transaction));
    }
    return modifiedTransactions;
  });

const getUnprocessedTransactions = () => axios.get<AcceptorTransactionHistoryResponse>(`${CONFIG.billUrl}/getAcceptorTransactionHistory`)
  .then((response) => {
    const { data } = response;
    const result: Array<TerminalAcceptorTransaction> = [];

    if (data?.transactions?.length) {
      data.transactions.forEach((transaction: AcceptorTransaction) => {
        if (transaction.status
          && (transaction.status === AcceptorsTransactionStatus.UNPROCESSED
            || transaction.status === AcceptorsTransactionStatus.OPEN)) {
          result.push(formatTransaction(transaction));
        }
      });
    }
    return result;
  });

/**
 * Tell acceptor that emptied cash box
 */
const transfer = (acceptor: AcceptorType) => axios.get<any>(`${CONFIG[`${acceptor}Url`]}/transfer`);

/**
     * It will send coin&bill service instruction to cancel transaction with platform
     */
const cancelTransaction = async (id: string)
: Promise<TerminalAcceptorTransaction | AxiosError | void> => {
  const { isDmApplicationRuntime } = useEnvironmentHandlerStore();
  if (!isDmApplicationRuntime()) {
    return axios.post(
      `${CONFIG.billUrl}/cancelTransaction`,
      {
        id,
      },
    ).then((response) => formatTransaction(response.data.transactions[0]));
  }
  const core = await deviceManagementService.getCore();
  core.connect();
  return core.on('connection', () => {
    const money = new Pocket(core);

    return money.payinCancel(id);
  });
};

/**
     * It will try to commit transaction to platform using coin&bill service
     *
     */
const retryTransaction = (id: string)
: Promise<TerminalAcceptorTransaction> | AxiosError => axios.post(
  `${CONFIG.billUrl}/retryTransaction`,
  {
    id,
  },
).then((response) => formatTransaction(response.data.transactions[0]));

const init = (): void => {
  CONFIG.billUrl = `http://127.0.0.1:${localAppConfigService.data.pocketPort || '8078'}`;
};

const getConfig = (): {
  billUrl: string,
  coinUrl: string,
} => CONFIG;

const stopAcceptor = (acceptor: AcceptorType) => axios.get<any>(`${CONFIG[`${acceptor}Url`]}/stop`);

const getAcceptorStatus = (acceptor: AcceptorType) => axios.get<any>(`${CONFIG[`${acceptor}Url`]}/get_status`);

export {
  init,
  getConfig,
  getAcceptorTransactionHistory,
  getAcceptorApoensReport,
  getUnprocessedTransactions,
  transfer,
  cancelTransaction,
  retryTransaction,
  stopAcceptor,
  getAcceptorStatus,
};
