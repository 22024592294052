import axios from 'axios';
import { SevenApiRoutes } from '@/modules/seven';
import type { TOddsLangChangeData } from './types';

const dispatchTabChange = (data: Record<string, string>) => axios.post(`${SevenApiRoutes.routes.localAdminConfig}/expose_app_window`, data);

const dispatchInfoPointActivation = () => axios.post(`${SevenApiRoutes.routes.localAdminConfig}/switch_next_window`, {
  windowTitle: '7Terminal0',
});

const dipatchOddsLanguageChange = (data: TOddsLangChangeData) => axios.post(
  process.env.LOCAL_SCM_PUBLISH_URL,
  {
    channel: 'localAppConfigChannel',
    type: 'oddsLanguageChange',
    data,
  },
);

const setOddsLanguageState = (data: TOddsLangChangeData) => axios.post(
  process.env.LOCAL_SCM_SET_STATE_URL,
  {
    channel: 'localAppConfigChannel',
    data,
  },
);

export {
  dispatchTabChange,
  dispatchInfoPointActivation,
  dipatchOddsLanguageChange,
  setOddsLanguageState,
};
