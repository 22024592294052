import { AxiosResponse } from 'axios';
import {
  betslipApiHelpers,
  type TicketResponse,
  type ProductTicketResponse,
  type CashoutPayload,
  type TicketOptionsResponse,
} from '@/modules/seven-betslip-api';
import { SubscribeCallback } from '@nsftx/seven-gravity-gateway/master';
import { isTboActive } from '@/common/services/helpers';
import { tboTicketsService } from '@/modules/tbo-tickets';
import { cashoutService } from '@/modules/tickets';
import { logService } from '@/common/services/logger';
import { useTicketCheckStore } from '@/modules/ticket-check';
import type { CashoutResponse, TboCashoutResponse } from '@/modules/seven-betslip-api/types';
import type { GravityAsyncEventResolve, GravityAsyncEventReject } from '../types';

const LOG_PREFIX = '[gravityGatewayCashoutService]';

const handleGatewayCashoutEvent: SubscribeCallback<
{
  ticket: TicketResponse | ProductTicketResponse,
  ticketOptions: CashoutPayload | TicketOptionsResponse
},
GravityAsyncEventResolve<AxiosResponse<TboCashoutResponse, any> | CashoutResponse>,
GravityAsyncEventReject
> = (slaveData) => {
  setTimeout(() => {
    const ticketInstance = betslipApiHelpers.createTicket(slaveData.data.ticket);
    const cashoutTicket = isTboActive()
      ? tboTicketsService.cashoutByOperator : cashoutService.cashoutTicket;
      // We may have scenario when user scans ticket which has PIN
      // bundled with code, ex. "X621G6XH6.2667".
      // So we first need to get this pin "2667" from store (which is set in the moment when
      // ticket is scanned), and then attach it to received ticket.
    const ticketCheckStore = useTicketCheckStore();
    const pin = ticketCheckStore.getTicketPin();
    ticketCheckStore.setResult(ticketInstance);
    if (pin) {
      ticketInstance.setPin(pin);
    }
    cashoutTicket(slaveData.data.ticketOptions as any, ticketInstance).then((res) => {
      logService.info(`${LOG_PREFIX} Cashout resolved`, {
        request_id: ticketInstance.getRequestUuid(),
        product_displayid: ticketInstance.getProductDisplayId(),
        ticket_code: ticketInstance.getDisplayId(),
        code: 'T_INTEGRATOR_CASHOUT_SUCCESS',
      });
      slaveData.resolve?.({ ...res });
      ticketCheckStore.clearTicketPin(true);
    }).catch((err) => {
      slaveData.reject?.(err);

      const { code, message } = err;
      logService.warn(`${LOG_PREFIX} Cashout failed`, {
        code: 'T_INTEGRATOR_CASHOUT_FAILED',
        usptream_code: code,
        upstream_message: message,
        request_id: ticketInstance.getRequestUuid(),
        product_displayid: ticketInstance.getProductDisplayId(),
        ticket_code: ticketInstance.getDisplayId(),
      });
      ticketCheckStore.clearTicketPin();
    });
  });
};
export {
  handleGatewayCashoutEvent,
};
